<!--添加富文本-->
<template>
  <div class="main">
    <div style="font-size: 26px; font-weight: bold;display:flex" >
      <div style="color:#C8C8C8;cursor: pointer;" @click='goback'>&lt;内容管理/</div>
      <div>添加课程包</div>
    </div>
    <div class="title">
      <div class="titleleft">
        <div style="align-items: center">
          <div class="line"></div>
          <div>内容设置</div>
        </div>
        <el-form label-position="right" style="margin-top: 40px">
          <div style="display: flex">
          <el-form-item label="是否有测试:" class="inp" style="display:flex;align-items: center;">
              <div style="display:flex;align-items: center;margin-left: 20px;">
                  <el-radio v-model="configValue" :label="0" style="display: flex;align-items: center;">是</el-radio>
                  <el-radio v-model="configValue" :label="1" style="display: flex;align-items: center;">否</el-radio>
                  
              </div>
            </el-form-item>
            <!-- <el-form-item label="品牌：" >
              <el-select v-model="brandId" placeholder="请选择" :disabled="id"  @change="changeBrandId">
                <el-option
                  v-for="item in peopleType"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                 
                >
                </el-option>
              </el-select>
            </el-form-item> -->
             <!-- <el-form-item label="分类：">
              <el-select v-model="parentId" placeholder="请选择">
                <el-option
                  v-for="item in classList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item> -->
          </div>
          <el-form-item label="课程包名称：" class="inp">
            <el-input v-model="name" placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item label="权重：" class="inp" >
            <el-input v-model="weight" placeholder="权重数字越大，越靠前"></el-input>
          </el-form-item>
          <el-form-item label="老师名称：" class="inp">
            <el-input v-model="teacherName" placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item label="课程包展示图：" class="inp">
             <input  type="file" @change="uploadimg2" id='file2' accept='image/*' ref='fileimg' style="color:#fff"/>
          </el-form-item>
          <el-form-item  class="inp" v-if='img' label="图片：">
             <!-- <img :src="img" alt="" style="width:200px"> -->
          
         
             <!-- <el-form-item class="inp" v-if="img" label="图片："> -->
            <div style="position:relative;width:200px" >
              <div style="width:100%;height:100%;position:absolute;background: rgba(0,0,0,0.1);" ></div>
              <img :src="img" alt="" style="width: 200px"  />
              <img  @click="deleteImg" src="../../images/delete.png" alt="" style="z-index:999;position: absolute;width: 25px;height: 25px;left: 50%;top: 50%;transform: translate(-50%, -50%);width:25px;height:25px">
            </div>
            </el-form-item>
             <div style="display: flex">
            <el-form-item label="课程包积分：" >
              <el-input v-model="point" placeholder="请输入内容" oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"></el-input>
            </el-form-item>
             <el-form-item label="课程包经验值：">
              <el-input v-model="experience" placeholder="请输入内容" oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"></el-input>
            </el-form-item>
          </div>
           
            

            <!-- <video src=""></video> -->
          <!-- </el-form-item> -->
          <!-- <el-form-item label="课程包简介：">
            <el-input
              type="textarea"
              :rows="5"
              v-model="textarea"
              :style="{ width: value != 4 && value != 5 ? '342px' : '500px' }"
            >
            </el-input>
          </el-form-item> -->
        </el-form>
      </div>
      <!-- <div style="flex-direction: column" v-if="value != 4 && value != 5">
        <div style="align-items: center">
          <div class="line"></div>
          <div style="display: flex; align-items: baseline">
            <div>视频上传</div>
            <div
              style="font-size: 14px; color: #bababa; margin-left: 11px"
              v-if="value == 1 || value == ''"
            >
              (选中则为答案，可多选)
            </div>
          </div>
        </div>
       
      </div> -->
    </div>
    <div>
      <!-- <vue-ueditor-wrap v-model="msg" :config="myConfig"></vue-ueditor-wrap> -->
      <!-- <quill-editor
          style="height:390px;width:100%;"
          v-model="msg"
          ref="richAnalysis"
          :options="options2"
          @change="quillchange"
        ></quill-editor> -->
    </div>
     <div style="display: flex;padding-top:50px">
      <el-button type="primary" style="margin: 20px auto; width: 200px" @click='add'
        >添加</el-button>
        <!-- <el-button type="primary" style="margin: 20px auto; width: 200px" @click='ritchtext'
        >获取富文本</el-button> -->
    </div>
   
     <input  type="file" @change="uploadimg" accept='image/*' ref='file' id="file" style="display:none"/>
  </div>
</template>

<script>
 // 工具栏配置
    const toolbarOptions = [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],
    
      [{'header': 1}, {'header': 2}],               // custom button values
      [{'list': 'ordered'}, {'list': 'bullet'}],
      [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
      [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
      [{'direction': 'rtl'}],                         // text direction
    
      [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
      [{'header': [1, 2, 3, 4, 5, 6, false]}],
    
      [{'color': []}, {'background': []}],          // dropdown with defaults from theme
      [{'font': []}],
      [{'align': []}],
      ['link', 'image', 'video'],
      ['clean']                                         // remove formatting button
    ]
    var COS = require('cos-js-sdk-v5');
      var cos = new COS({
            getAuthorization: function (options, callback) {
              getCosCredit().then(result=>{
                 var data = result.data.data;
                        var credentials = data && data.credentials;
                        if (!data || !credentials) return console.error('credentials invalid');
                       sessionStorage.setItem('bucket', data.bucket)
                        callback({
                            TmpSecretId: credentials.tmpSecretId,
                            TmpSecretKey: credentials.tmpSecretKey,
                            XCosSecurityToken: credentials.sessionToken,
                            // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
                            StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
                            ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000900
                        });
              })
            }
        });
import VueUeditorWrap from "vue-ueditor-wrap";
import {levelList,contentsaveOrUpdate,getCosCredit,getRichText,coursesave,courseupdate,courseClassList,addCourseClass} from '../../apis/index'
export default {
  data() {
    return {
      weight:0,
      configValue:1,
        point:'',
        experience:'',
        teacherName:'',
       brandId:'',
      peopleType:[
        {
          id:1,
          name:'嘉顿肯妮'
        },
        {
          id:2,
          name:'瑞妮朵拉'
        },
        {
          id:3,
          name:'素颜+'
        },
        {
          id:10,
          name:'外部学员+'
        },
      ],
      classList:[],
      parentId:'',
      filename:"",
      img:'',
      name:'',
       levelList:[],
      levelId:'0',
      input: "",
      fileList: [],
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      textarea: "",
      value: "1",
      checkList: [],
      radio: "1",
      options: [
        {
          value: "1",
          label: "富文本",
        },
        {
          value: "2",
          label: "视频",
        },
      ],
      options2: {
        theme: "snow",
        placeholder: "请输入内容",
        modules:{
          toolbar:{
             container: toolbarOptions,  // 工具栏
              handlers: {
                                'image': function (value) {
                                    if (value) {
                                      document.getElementById('file').click()
                                        // alert('自定义图片')
                                    } else {
                                        this.quill.format('image', false);
                                    }
                                }
                            }
          }
        }
        // modules: {
        //   toolbar: [
        //     ["bold", "italic", "underline", "strike"], // 加粗，斜体，下划线，删除线
        //     //['blockquote', 'code-block'],                      //引用，代码块
        //     [{ header: 1 }, { header: 2 }], // 几级标题
        //     [{ list: "ordered" }, { list: "bullet" }], // 有序列表，无序列表
        //     [{ script: "sub" }, { script: "super" }], // 下角标，上角标
        //     [{ indent: "-1" }, { indent: "+1" }], // 缩进
        //     //[{ direction: "rtl" }], // 文字输入方向
        //     [{ size: ["small", false, "large", "huge"] }], // 字体大小
        //     [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
        //     [{ color: [] }, { background: [] }], // 颜色选择
        //     [{ font: [] }], // 字体
        //     [{ align: [] }], // 居中
        //     ["clean"],　　　　//清除样式
        //     [("link", "image","video")],//控制能上传的内容
        //   ],
        // },
      },
      msg: "",
     
    };
  },
  components: {
    VueUeditorWrap,
  },
  watch: {},
    created(){
      //  this.msg='https://testuser-1308336073.cos.ap-nanjing.myqcloud.com/text1'
       let that=this
       this.id=this.$route.query.id
       this.brandId=this.$route.query.brandId
       
    // if(that.$route.query.id){
    //   that.leave().then(res=>{
    //     let row=that.$route.query
    //     that.levelId=Number(row.levelId),
    //     that.name=row.name,
    //     that.textarea=row.des
    //    that.img='https://sihuankangyuan.cn'+row.picUri
    //    console.log(that.img)
    //     // that.msg=row.contentText,
    //     that.id=row.id
    //     this.brandId=Number(row.brandId)
    //     //  that.img=row.logoUri
    // })
    // // getRichText(that.$route.query.resourceUri).then(res=>{
    // //    that.msg=res.data
    // // })
    // //  cos.options.getAuthorization ('',function(obj){//下载文件
    // //       cos.getObject({
    // //         Bucket: sessionStorage.getItem('bucket'), /* 必须 */
    // //         Region: 'ap-nanjing',     /* 存储桶所在地域，必须字段 */
    // //         Key: that.$route.query.resourceUri,              /* 必须 */
    // //     }, function(err, data) {
    // //         console.log(err || data.Body);
    // //         that.msg=data.Body
    // //     });
    // //     })
    // }else{
    //   that.leave()
    // }
    
    
  },
  
  methods: {
      changeBrandId(){
      this.getClassList()
  },
  async getClassList(){//根据品牌获取分类列表
      const params={
        brandId:this.brandId
      }
     const {data}=await courseClassList(params)
     if(data.code==200){
    
       this.tableData=data.data.children
       let list=[]
       data.data.children.map(item=>{
           list.push({
               name:item.courseRecord.name,
               id:item.courseRecord.id,
           })
       })
       this.classList=list
       console.log(list)
       console.log(data.data.children)
     }
    },
      deleteImg(){//删除图片
      document.getElementById('file2').value=''
    this.img=''
    },
     uploadimg2(e){//上传图片
      if(this.$refs['fileimg'].files[0]){
        let filename = this.$refs['fileimg'].files[0].name
        let selectFileobj = this.$refs['fileimg'].files[0]
        // console.log(selectFileobj)
        this.upload2(selectFileobj)
      }
    },
    upload2(selectFileobj){
      let that=this
           let name = selectFileobj.name
    //    if(that.levelId==''){
    //       that.$message.error('请先选择等级')
    //        document.getElementById('file2').value=''
    //       return 
    //     }
        let filename ='content/resource/LV'+that.levelId+'/'+'image/'+
          name.slice(0, name.length - 4) +
          new Date().getTime() +
          name.slice(-4);
          console.log(filename)
        cos.options.getAuthorization ('',function(obj){
          cos.putObject({
          Bucket: sessionStorage.getItem('bucket'), /* 必须 */
          Region: 'ap-nanjing',     /* 存储桶所在地域，必须字段 */
          Key: filename,              /* 必须 */
          StorageClass: 'STANDARD',
          Body: selectFileobj, // 上传文件对象
          onProgress: function(progressData) {
              // console.log(JSON.stringify(progressData));
              console.log(progressData)
          }
        }, function(err, data) {
          console.log(err || data);
          if(data){
            // let url='https://'+data.Location
             let url=data.Location.replace(/content-1308336073.cos.ap-nanjing.myqcloud.com/g,'https://sihuankangyuan.cn')
            that.img=url
          }
        });
        })
         
    },
    quillchange(delta, oldDelta, source){
      console.log(delta, oldDelta, source)
    },
    uploadimg(e){//上传图片
    
      if(this.$refs['file'].files[0]){
        let filename = this.$refs['file'].files[0].name
        let selectFileobj = this.$refs['file'].files[0]
        // console.log(selectFileobj)
        this.upload(selectFileobj)

      }
    },
     upload(selectFileobj){
      let that=this
      let name = selectFileobj.name
       if(that.levelId==''){
          that.$message.error('请先选择等级')
          document.getElementById('file').value=''
          return 
        }
        let filename ='content/resource/LV'+that.levelId+'/'+'image/'+
          name.slice(0, name.length - 4) +
          new Date().getTime() +
          name.slice(-4);
          console.log(filename)
     var cos = new COS({
            getAuthorization: function (options, callback) {
              getCosCredit().then(result=>{
                 var data = result.data.data;
                        var credentials = data && data.credentials;
                        if (!data || !credentials) return console.error('credentials invalid');
                       sessionStorage.setItem('bucket', data.bucket)
                        callback({
                            TmpSecretId: credentials.tmpSecretId,
                            TmpSecretKey: credentials.tmpSecretKey,
                            XCosSecurityToken: credentials.sessionToken,
                            // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
                            StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
                            ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000900
                        });
              })
            }
        });
        cos.options.getAuthorization ('',function(obj){
          cos.putObject({
          Bucket: sessionStorage.getItem('bucket'), /* 必须 */
          Region: 'ap-nanjing',     /* 存储桶所在地域，必须字段 */
          Key: filename,              /* 必须 */
          StorageClass: 'STANDARD',
          Body: selectFileobj, // 上传文件对象
          onProgress: function(progressData) {
              // console.log(JSON.stringify(progressData));
              console.log(progressData)
          }
        }, function(err, data) {
          console.log(err || data);
          if(data){
           
              // let url='https://'+data.Location
               let url=data.Location.replace(/content-1308336073.cos.ap-nanjing.myqcloud.com/g,'https://sihuankangyuan.cn')
             let quill = that.$refs.richAnalysis.quill
             let length = quill.getSelection().index;
            // 插入图片，url为服务器返回的图片链接地址
            quill.insertEmbed(length, 'image', url)
            // 调整光标到最后
            quill.setSelection(length + 1)
          }
        });
        })
         
    },
     richText(selectFileobj){
      let that=this
       if(that.levelId==''){
          that.$message.error('请先选择等级')
          return 
        }
        let randomNum=Math.random().toString().slice(2)
        let filename ='content/resource/LV'+that.levelId+'/'+'richText/'+randomNum+new Date().getTime()
        this.filename=filename
          console.log(filename)
     var cos = new COS({
            getAuthorization: function (options, callback) {
              getCosCredit().then(result=>{
                 var data = result.data.data;
                        var credentials = data && data.credentials;
                        if (!data || !credentials) return console.error('credentials invalid');
                       sessionStorage.setItem('bucket', data.bucket)
                        callback({
                            TmpSecretId: credentials.tmpSecretId,
                            TmpSecretKey: credentials.tmpSecretKey,
                            XCosSecurityToken: credentials.sessionToken,
                            // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
                            StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
                            ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000900
                        });
              })
            }
        });
        cos.options.getAuthorization ('',function(obj){
          cos.putObject({
          Bucket: sessionStorage.getItem('bucket'), /* 必须 */
          Region: 'ap-nanjing',     /* 存储桶所在地域，必须字段 */
          Key: filename,              /* 必须 */
          StorageClass: 'STANDARD',
          Body: selectFileobj, // 上传文件对象
          onProgress: function(progressData) {
              // console.log(JSON.stringify(progressData));
              console.log(progressData)
          }
        }, function(err, data) {
          console.log(err || data);
          if(data){
            console.log(data)
           const params={
                type:3,
                levelId:that.levelId,
                name:that.name,
                // contentText:this.msg,
                resourceUri:that.filename,
                logoUri:that.img,
                id:that.id
              }
               contentsaveOrUpdate(params).then(res=>{
                  if(res.data.code==200){
                that.$message.success('操作成功')
                that.$router.go(-1)
              }else{
                that.$message.error(res.data.msg)
              }
                })
            //   let url='https://'+data.Location
            //  let quill = that.$refs.richAnalysis.quill
            //  let length = quill.getSelection().index;
            // // 插入图片，url为服务器返回的图片链接地址
            // quill.insertEmbed(length, 'image', url)
            // // 调整光标到最后
            // quill.setSelection(length + 1)
          }
        });
        })
         
    },
    ritchtext(){
      console.log(this.msg)
    },
     async leave(){//获取等级列表
     const {data}=await levelList({roleId:3})
     if(data.code==200){
      
       this.levelList=data.data
     }else{
       this.$message.error(data.msg)
     }
    },
     add(){
    const params={
        brandId:this.brandId,
        dataList:[
            {
                configValue:this.configValue,
                parentId:this.id,
                name:this.name,
                picUri:this.img,
                point:this.point,
                experience:this.experience,
                teacherName:this.teacherName,
                weight:this.weight
            }
        ]
    } 
    addCourseClass(params).then(res=>{
        if(res.data.code==200){
       this.$message.success('操作成功')
       this.$router.go(-1)
    }
     })
    //   if(this.$route.query.id){//更新
    //       addCourseClass(params).then(res=>{
    //     if(res.data.code==200){
    //    this.$message.success('操作成功')
    //    this.$router.go(-1)
    //  }else{
    //    this.$message.error(res.data.msg)
    //  }
    //   }
    //   )
    //   }else{//保存
    //   const params={
    //       brandId:this.brandId,
    //       dataList:[
    //           {
    //               name:this.name,
    //               parentId:this.parentId
    //           }
    //       ]
    //   }
    //        addCourseClass(params).then(res=>{
    //     if(res.data.code==200){
    //    this.$message.success('操作成功')
    //    this.$router.go(-1)
    //  }else{
    //    this.$message.error(res.data.msg)
    //  }
    //   }
    //   )
    //   }
     
    // console.log(params)
    //  const {data}=await contentsaveOrUpdate(params)
     
    //   })
      
     
    },
    goback(){
      this.$router.go(-1)
    },
  },
};
</script>

<style lang='less' scoped>
.main {
  background: #fff;
  margin: 19px 0;
  padding: 23px;
  border-radius: 14px;
}
.title {
  display: flex;
  // justify-content: space-between;
  align-items: flex-start;
  div {
    display: flex;
  }
  .line {
    width: 9px;
    height: 22px;
    border-radius: 6px;
    background: #409eff;
    margin-right: 11px;
  }
}
.title > div {
  width: 50%;
  margin: 29px 0;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  //   align-items: center;
}
.titleleft {
  display: flex;
  flex-direction: column;
}
/deep/.el-form-item__label {
  width: 100px;
}
/deep/.el-radio__label,
/deep/.el-checkbox__label {
  display: flex;
  align-items: center;
}
.inp /deep/.el-form-item__content {
  width: 88%;
}
</style>
